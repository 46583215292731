import React from 'react';
import styled from 'styled-components';

import { ContactItems } from '../Footer';
import { PrimaryHeading } from '../reusables/textElements';
import { breakpoints, fonts } from '../../lib/constants';

const Intro = styled.div({
  fontFamily: fonts.bold,
  textTransform: 'uppercase',
  fontSize: 24,
  marginBottom: 5,
});

const Container = styled.div({
  marginTop: 50,
  display: 'grid',
  gridTemplateColumns: '1fr 400px',
  columnGap: 20,
  marginBottom: 50,
  [`@media (max-width: 1000px)`]: {
    display: 'block',
  },
});

const Text = styled.p({
  fontSize: 18,
  lineHeight: '24px',
  margin: 0,
  marginBottom: 20,
});

function TopSection() {
  return (
    <Container>
      <div>
        <Intro>Hör gärna av dig till</Intro>
        <PrimaryHeading>OSS</PrimaryHeading>
        <ContactItems
          css={{
            gridTemplateColumns: '1fr 1fr 1fr',
            marginTop: 50,
            marginBottom: 30,
            maxWidth: 600,
          }}
          isCenterAligned={false}
        />
      </div>
      <Text>
        Nyfiken på hur beteendedesign och nudging kan inspirera och utveckla din
        organisation mot fler hållbara beteenden? Då har du kommit helt rätt!
        Vill du veta mer om oss, vilka vi är och vad vi gör? Tveka inte att höra
        av dig!
      </Text>
    </Container>
  );
}

export default TopSection;
