import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { Paragraph2 } from '../reusables/textElements';
import { ContactItems } from '../Footer';
import { gutters } from '../../lib/constants';

const Container = styled.div({
  marginTop: 50,
  marginBottom: 50,
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  columnGap: gutters.vertical,
  rowGap: gutters.vertical * 2,
  [`@media (max-width: 1000px)`]: {
    gridTemplateColumns: '1fr',
  },
});
const SingleCard = styled.div({
  maxWidth: 435,
  width: '100%',
});
const Link = styled(Paragraph2)({
  textDecoration: 'underline',
  marginBottom: 2,
});
const StyledImg = styled(Img)({
  marginBottom: 15,
});

function Person({ image, name, title, email, phone }) {
  return (
    <SingleCard>
      <StyledImg alt={name} sizes={image.sizes} />
      <Paragraph2 isBold css={{ marginBottom: 3 }}>
        {name}
      </Paragraph2>
      <Paragraph2 css={{ marginBottom: 10 }}>{title}</Paragraph2>
      <Link href={`mailto:${email}`}>{email}</Link>
      <Link href={`tel:${phone}`}>{phone}</Link>
    </SingleCard>
  );
}

function PersonCards({ items }) {
  return (
    <Container>
      {items.map((item, index) => (
        <Person key={index} {...item} />
      ))}
    </Container>
  );
}

export default PersonCards;
