import { graphql } from 'gatsby';
import React from 'react';

import AppChrome from '../components/AppChrome';
import ContentWrapper from '../components/reusables/ContentWrapper';
import PersonCards from '../components/contact/PersonCards';
import TopNav from '../components/TopNav';
import TopSection from '../components/contact/TopSection';

function Contact({ data }) {
  const items = data.allContentfulSettings.edges[0].node.contactPagePeople;
  return (
    <AppChrome
      title="Kontakta oss"
      og={{
        title: 'Kontakta oss',
        description: `
          Beteendelabbet
          Svartmangatan 9
          111 29 Stockholm
        `,
        image: items[0].image.sizes.src,
      }}
    >
      <TopNav />
      <ContentWrapper>
        <TopSection />
        <PersonCards items={items} />
      </ContentWrapper>
    </AppChrome>
  );
}

export default Contact;

export const pageQuery = graphql`
  {
    allContentfulSettings {
      edges {
        node {
          contactPagePeople {
            name
            email
            phone
            title
            image {
              sizes(
                maxWidth: 870
                maxHeight: 600
                background: "rgb:000000"
                resizingBehavior: FILL
              ) {
                ...GatsbyContentfulSizes_withWebp
              }
            }
            bio {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`;
